import React from "react";
import QueryItem from "../../common/QueryItem";
import { queries } from "../../utils/constants";

function SuperAdminQueries() {
  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3>Open queries</h3>
        {queries.map((x, i) => (
          <div className="py-2" key={i}>
            <QueryItem name={x.name} title={x.remarks} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SuperAdminQueries;
