import React from "react";
import Card from "../../common/card";
import { products } from "../../utils/constants";

function SchoolAdminMarketPlace() {
  return (
    <div className="mt-5">
      <div className="container">
        <h3>Market Place</h3>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-4 col-12 col-md-4" key={i}>
              <Card title={x} category="product" index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SchoolAdminMarketPlace;
