import React from "react";
import BlogCard from "../../common/BlogCard";
import { clubs, products } from "../../utils/constants";

function StudentHome() {
  return (
    <div className="mt-5">
      <div className="container">
        <h4>Explore Products</h4>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"product"} index={i} />
            </div>
          ))}
        </div>
      </div>

      {/* <div className="container mt-5">
        <h4>Explore Posts</h4>
        <div className="d-flex flex-wrap">
          {Array(4)
            .fill(0)
            .map((x, i) => (
              <div className="p-3 col-12 col-md-3" key={i}>
                <DashboardCard />
              </div>
            ))}
        </div>
      </div> */}

      <div className="container mt-5">
        <h4>Explore Clubs</h4>
        <div className="d-flex flex-wrap">
          {clubs.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"club"} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StudentHome;
