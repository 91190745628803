import React from "react";
import Card from "../../common/card";
import { clubs } from "../../utils/constants";

function StudentClubs() {
  return (
    <div className="mt-5">
      <div className="container">
        <h3>Clubs</h3>
        <div className="d-flex flex-wrap">
          {clubs.map((x, i) => (
            <div className="p-4 col-12 col-md-4" key={i}>
              <Card title={x} category={"club"} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StudentClubs;
