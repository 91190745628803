import React from "react";

function Chatbox() {
  const [show, setShow] = React.useState(false);
  return (
    <div
      className="bg-white shadow border border-info rounded-top"
      style={{
        position: "fixed",
        bottom: 0,
        right: 10,
        width: 300,
        zIndex: 99,
      }}
    >
      <div>
        <div
          role={"button"}
          className="bg-cyan-light p-3"
          onClick={() => setShow(!show)}
        >
          Chat with us!
        </div>
        {show && (
          <div style={{ height: 300 }} className="position-relative">
            <div className="p-3 d-flex ">
              <div
                style={{ height: 30, width: 30, borderRadius: 50 }}
                className="bg-info"
              ></div>
              <div className="mx-2">
                <p className="mb-0 bg-light px-3 py-2 rounded border border-info">
                  Welcome to Mercado
                </p>
                <small className="m-2">04:35 PM</small>
              </div>
            </div>

            <div className="p-2 d-flex position-absolute bottom-0 input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Enter message"
              />
              <button className="btn btn-dark" type="button" id="button-addon2">
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Chatbox;
