import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Public Pages
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import Contact from "./pages/contact";
import Auth from "./pages/auth";
import Blogs from "./pages/blogs";

// Layouts
import StudentLayout from "./layout/student";
import MainLayout from "./layout/main";
import BusinessOwnerLayout from "./layout/businessOwner";
import SchoolAdminLayout from "./layout/schoolAdmin";
import SuperAdminLayout from "./layout/superAdmin";

// student Pages
import StudentHome from "./pages/student/home";
import StudentClubs from "./pages/student/clubs";
import StudentPosts from "./pages/student/posts";
import StudentProfile from "./pages/student/profile";
import StudentProducts from "./pages/student/products";
import StudentCart from "./pages/student/cart";

// Business owner Pages
import BusinessOwnerHome from "./pages/businessOwner/home";
import BusinessOwnerBusiness from "./pages/businessOwner/business";
import BusinessOwnerAds from "./pages/businessOwner/advertisements";
import BusinessOwnerProducts from "./pages/businessOwner/products";
import BusinessOwnerOrders from "./pages/businessOwner/orders";
import BusinessOwnerProfile from "./pages/businessOwner/profile";

// School Admin Pages
import SchoolAdminHome from "./pages/schoolAdmin/home";
import SchoolAdminStudents from "./pages/schoolAdmin/students";
import SchoolAdminPosts from "./pages/schoolAdmin/posts";
import SchoolAdminBusinessOwners from "./pages/schoolAdmin/businessOwners";
import SchoolAdminMarketPlace from "./pages/schoolAdmin/market-place";
import SchoolAdminMarketClubs from "./pages/schoolAdmin/clubs";

// Super Admin Pages
import SuperAdminHome from "./pages/superAdmin/home";
import SuperAdminUsers from "./pages/superAdmin/users";
import SuperAdminSchools from "./pages/superAdmin/schools";
import SuperAdminQueries from "./pages/superAdmin/queries";
import Chatbox from "./common/Chatbox";

const MainRoutes = [
  {
    path: "",
    component: <Home />,
  },
  {
    path: "about",
    component: <About />,
  },
  {
    path: "services",
    component: <Services />,
  },
  {
    path: "blogs",
    component: <Blogs />,
  },
  {
    path: "contact",
    component: <Contact />,
  },
  {
    path: "login",
    component: <Auth />,
  },
];

const StudentRoutes = [
  {
    path: "",
    component: <StudentHome />,
  },
  {
    path: "products",
    component: <StudentProducts />,
  },
  {
    path: "clubs",
    component: <StudentClubs />,
  },
  {
    path: "posts",
    component: <StudentPosts />,
  },
  {
    path: "cart",
    component: <StudentCart />,
  },
  {
    path: "profile",
    component: <StudentProfile />,
  },
];

const BusinessOwnerRoutes = [
  {
    path: "",
    component: <BusinessOwnerHome />,
  },
  {
    path: "business",
    component: <BusinessOwnerBusiness />,
  },
  {
    path: "advertisements",
    component: <BusinessOwnerAds />,
  },
  {
    path: "products",
    component: <BusinessOwnerProducts />,
  },
  {
    path: "orders",
    component: <BusinessOwnerOrders />,
  },
  {
    path: "profile",
    component: <BusinessOwnerProfile />,
  },
];

const SchoolAdminRoutes = [
  {
    path: "",
    component: <SchoolAdminHome />,
  },
  {
    path: "students",
    component: <SchoolAdminStudents />,
  },
  {
    path: "posts",
    component: <SchoolAdminPosts />,
  },
  {
    path: "business-owners",
    component: <SchoolAdminBusinessOwners />,
  },
  {
    path: "clubs",
    component: <SchoolAdminMarketClubs />,
  },
  {
    path: "market-place",
    component: <SchoolAdminMarketPlace />,
  },
  {
    path: "profile",
    component: <BusinessOwnerProfile />,
  },
];

const SuperAdminRoutes = [
  {
    path: "",
    component: <SuperAdminHome />,
  },
  {
    path: "users",
    component: <SuperAdminUsers />,
  },
  {
    path: "schools",
    component: <SuperAdminSchools />,
  },

  {
    path: "queries",
    component: <SuperAdminQueries />,
  },
];

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/">
          {MainRoutes.map((el, index) => (
            <Route
              key={index}
              path={el.path}
              element={<MainLayout>{el.component}</MainLayout>}
            />
          ))}
        </Route>
        <Route path="/super-admin">
          {SuperAdminRoutes.map((el, index) => (
            <Route
              key={index}
              path={el.path}
              element={<SuperAdminLayout>{el.component}</SuperAdminLayout>}
            />
          ))}
        </Route>
        <Route path="/school-admin">
          {SchoolAdminRoutes.map((el, index) => (
            <Route
              key={index}
              path={el.path}
              element={<SchoolAdminLayout>{el.component}</SchoolAdminLayout>}
            />
          ))}
        </Route>
        <Route path="/business-owner">
          {BusinessOwnerRoutes.map((el, index) => (
            <Route
              key={index}
              path={el.path}
              element={
                <BusinessOwnerLayout>{el.component}</BusinessOwnerLayout>
              }
            />
          ))}
        </Route>
        <Route path="/user">
          {StudentRoutes.map((el, index) => (
            <Route
              key={index}
              path={el.path}
              element={<StudentLayout>{el.component}</StudentLayout>}
            />
          ))}
        </Route>
      </Routes>
    </Router>
  );
};

function App() {
  return (
    <>
      <AppRouter />
      <Chatbox />
    </>
  );
}

export default App;
