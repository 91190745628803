import React from "react";
import BlogCard from "../../common/BlogCard";
import DashboardCard from "../../common/DashboardCard";
import {
  advertisements,
  businessOwnerHome,
  products,
} from "../../utils/constants";

function BusinessOwnerHome() {
  return (
    <div className="mt-5">
      <div className="container">
        <h4>Explore Business</h4>
        <div className="d-flex flex-wrap">
          {businessOwnerHome.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <DashboardCard title={x.name} count={x.count} />
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-5">
        <h4>Explore Advertisements</h4>
        <div className="d-flex flex-wrap">
          {advertisements.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"ad"} index={i} />
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-5">
        <h4>Explore Products</h4>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"product"} index={i} />
            </div>
          ))}
        </div>
      </div>

      <div className="container mt-5">
        <h4>Explore Orders</h4>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"product"} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerHome;
