import React from "react";

function CartItem(props) {
  return (
    <div className="card">
      <div className="card-body px-4 d-flex justify-content-between">
        <h5 className="card-title mb-0">{props.title}</h5>
        <p className="card-text">Rs. 100</p>
      </div>
    </div>
  );
}

export default CartItem;
