import React from "react";

function About() {
  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">About Us</h3>
        </div>
      </div>
      <article className="container my-5">
        <p>
          This website's purpose is to serve as a platform or a mediator between buyers
          and sellers. It enables students (users) to browse multiple pricing lists for
          the user-selected goods and helps clients choose the one that will save them
          the most money and allows them to sell their goods as well if required.
        </p>
        <p>
          It enables business owners to establish stronger connections with their clients and identify their target market.
        </p>
      </article>
    </div>
  );
}

export default About;
