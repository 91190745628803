import React from "react";
import BlogCard from "../../common/BlogCard";
import DashboardCard from "../../common/DashboardCard";
import { clubs, products } from "../../utils/constants";

function StudentProfile() {
  return (
    <div className="mt-5">
      <div className="container mb-5">
        <h3>Profile Details</h3>
        <div className="d-flex flex-wrap mt-4">
          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Full Name</small>
            <h4>John Doe</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Email</small>
            <h4>jondoe@gmail.com</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Major</small>
            <h4>Social Sciences</h4>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <h3>Products</h3>
        <div className="d-flex flex-wrap">
          {products.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"product"} index={i} />
            </div>
          ))}
        </div>
      </div>

      <div className="container mb-5">
        <h3>Posts</h3>
        <div className="d-flex flex-wrap">
          {Array(4)
            .fill(0)
            .map((x, i) => (
              <div className="p-3 col-12 col-md-3" key={i}>
                <DashboardCard />
              </div>
            ))}
        </div>
      </div>

      <div className="container mb-5">
        <h3>Clubs</h3>
        <div className="d-flex flex-wrap">
          {clubs.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <BlogCard title={"club"} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
