import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  const navLinks = [
    {
      label: "Dashboard",
      link: "/business-owner",
    },
    {
      label: "Business Page",
      link: "/business-owner/business",
    },
    {
      label: "Advertisements",
      link: "/business-owner/advertisements",
    },
    {
      label: "Products",
      link: "/business-owner/products",
    },
    {
      label: "Orders",
      link: "/business-owner/orders",
    },
    {
      label: "Profile",
      link: "/business-owner/profile",
    },
  ];

  const RenderProfileNavLink = () => {
    return (
      <li className="nav-item dropdown ">
        <a
          className="nav-link dropdown-toggle d-flex align-items-center text-white"
          href="javascript.void(0)"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="material-symbols-outlined">account_circle</span>
        </a>
        <ul className="dropdown-menu">
          <li>
            <a className="dropdown-item " href="javascript.void(0)">
              My Profile
            </a>
          </li>
          <li>
            <a
              className="dropdown-item "
              onClick={() => (window.location.href = "/")}
            >
              Logout
            </a>
          </li>
        </ul>
      </li>
    );
  };
  return (
    <nav className="navbar navbar-expand-lg bg-cyan-dark sticky-top ">
      <div className="container-fluid d-flex justify-content-center">
        <div className="justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            {navLinks.map((item, index) => (
              <li
                key={index}
                className="nav-item mx-3 d-flex align-items-center "
              >
                <NavLink className="nav-link text-white" to={item.link}>
                  {item.label === "Profile" ? (
                    <RenderProfileNavLink />
                  ) : (
                    item.label
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

function StudentLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default StudentLayout;
