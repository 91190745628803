import React from "react";
import DashboardCard from "../../common/DashboardCard";
import { businessOwnerHome } from "../../utils/constants";

function BusinessOwnerBusiness() {
  return (
    <div className="mt-5">
      <div className="container">
        <h3>Business</h3>
        <div className="d-flex flex-wrap">
          {businessOwnerHome.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <DashboardCard title={x.name} count={x.count} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerBusiness;
