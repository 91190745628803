import React from "react";
import CartItem from "../../common/CartItem";
import { products } from "../../utils/constants";

function StudentCart() {
  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3>My Cart</h3>
        {products.map((x, i) => (
          <div className="py-2" key={i}>
            <CartItem title={x} />
          </div>
        ))}
        <div className="px-2 d-flex justify-content-between align-items-center mt-3">
          <div>
            <button type="submit" className="btn btn-primary px-5">
              Checkout
            </button>
          </div>
          <div>
            <span>Total</span>
            <p className="">Rs. 400</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCart;
