import React from "react";

function Auth() {
  const [view, setView] = React.useState(true);

  const AuthWrapper = ({ children }) => {
    return (
      <section className="text-center card rounded col-10 col-md-5 p-5">
        {children}
      </section>
    );
  };

  const LoginForm = () => {
    return (
      <form>
        <div className="mb-4">
          <input type="email" className="form-control" placeholder="Email" />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button type="submit" className="btn btn-primary px-5 py-2 w-100">
            Submit
          </button>
        </div>
      </form>
    );
  };

  const RegisterForm = () => {
    return (
      <form>
        <div className="mb-4">
          <input type="text" className="form-control" placeholder="Name" />
        </div>
        <div className="mb-4">
          <input
            type="date"
            className="form-control"
            placeholder="Date of birth"
          />
        </div>
        <div className="mb-4">
          <input type="text" className="form-control" placeholder="Address" />
        </div>
        <div className="mb-4">
          <input type="email" className="form-control" placeholder="Email" />
        </div>
        <div className="mb-4">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
          />
        </div>
        <div className="d-flex  justify-content-center">
          <button type="submit" className="btn btn-primary px-5 py-2 w-100">
            Submit
          </button>
        </div>
      </form>
    );
  };

  return (
    <main>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Login / Register</h3>
        </div>
      </div>
      <div className="container d-flex justify-content-center py-5">
        <AuthWrapper>
          <h3>{view ? "Sign in" : "Sign Up"}</h3>
          <p className="mt-4">Sign in for better experience</p>

          {view ? <LoginForm /> : <RegisterForm />}

          {view && <p className="mt-4 text-center">Reset password </p>}
          <p
            onClick={() => setView(!view)}
            className="mt-3 text-center"
            role="button"
          >
            {view ? "Sign Up" : "Already user? Sign in"}
          </p>
        </AuthWrapper>
      </div>
    </main>
  );
}

export default Auth;
