import React from "react";
import DashboardCard from "../../common/DashboardCard";
import { LineChart } from "../../common/LineChart";
import { superAdminHome } from "../../utils/constants";

function SuperAdminHome() {
  return (
    <div className="mt-5">
      <div className="container d-flex flex-wrap">
        {superAdminHome.map((x, i) => (
          <div className="p-3 col-12 col-md-3" key={i}>
            <DashboardCard title={x.name} count={x.count} />
          </div>
        ))}
      </div>

      <div className="container mt-5">
        <div className="row justify-content-between">
          <div className="col-12 col-md-5 mb-5">
            <LineChart />
          </div>
          <div className="col-12 col-md-5 mb-5">
            <LineChart />
          </div>
          <div className="col-12 col-md-5 mb-5">
            <LineChart />
          </div>
          <div className="col-12 col-md-5 mb-5">
            <LineChart />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminHome;
