import React from "react";

function Blogs() {
  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold"> Blogs</h3>
        </div>
      </div>
      <div className="w-100 border border-dark position-relative iframe-loader posts-height">
        <iframe
          src="https://foded92621haboty.wordpress.com/"
          title="Mercado Escolar Blogs"
          width={"100%"}
          height={"100%"}
        ></iframe>
        <div className="hide-header"></div>
      </div>
    </div>
  );
}

export default Blogs;
