import React from "react";
import Card from "../../common/card";
import { advertisements } from "../../utils/constants";

function BusinessOwnerAds() {
  return (
    <div className="mt-5">
      <div className="container">
        <h4>Advertisements</h4>
        <div className="d-flex flex-wrap">
          {advertisements.map((x, i) => (
            <div className="p-3 col-12 col-md-3" key={i}>
              <Card title={x} category={"ad"} index={i} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerAds;
