import React from "react";

function BusinessOwnerProfile() {
  return (
    <div className="mt-5">
      <div className="container mb-5">
        <h3>Profile Details</h3>
        <div className="d-flex flex-wrap mt-4">
          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Full Name</small>
            <h4>John Doe</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Email</small>
            <h4>johndoe@gmail.com</h4>
          </div>

          <div className="p-3 rounded shadow-sm mx-3 bg-light col-12 col-md-3">
            <small>Major</small>
            <h4>Social Sciences</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerProfile;
