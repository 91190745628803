import React from "react";

function QueryItem(props) {
  return (
    <div className="card">
      <div className="card-body px-4 d-flex justify-content-between">
        <div>
          <h5 className="card-title mb-0">{props.title}</h5>
          <small className="text-secondary">{props.name}</small>
        </div>
        <div className="d-flex">
          <p role={"button"} className="card-text mx-3 mb-0  text-info">
            Open
          </p>
          <p role={"button"} className="card-text mx-3 mb-0  text-danger">
            Close
          </p>
        </div>
      </div>
    </div>
  );
}

export default QueryItem;
