import React from "react";
import { NavLink } from "react-router-dom";

function Navbar() {
  const navLinks = [
    {
      label: "Dashboard",
      link: "/super-admin",
    },
    {
      label: "Schools",
      link: "/super-admin/schools",
    },
    {
      label: "Users",
      link: "/super-admin/users",
    },
    {
      label: "Resolve Queries",
      link: "/super-admin/queries",
    },
  ];

  return (
    <nav className="navbar navbar-expand-lg bg-cyan-dark sticky-top ">
      <div className="container-fluid d-flex justify-content-center">
        <div className="justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            {navLinks.map((item, index) => (
              <li
                key={index}
                className="nav-item mx-3 d-flex align-items-center "
              >
                <NavLink className="nav-link text-white" to={item.link}>
                  {item.label}
                </NavLink>
              </li>
            ))}
            <li className="nav-item mx-3 d-flex align-items-center ">
              <a
                href="#"
                className="dropdown-item nav-link text-white cursor-pointer"
                onClick={() => (window.location.href = "/")}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

function StudentLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}

export default StudentLayout;
