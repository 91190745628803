import React from "react";

function ListItem(props) {
  return (
    <div className="card">
      <div className="card-body px-4 d-flex justify-content-between">
        <h5 className="card-title mb-0">{props.title}</h5>
        <div className="d-flex">
          <p role={"button"} className="card-text mx-3 mb-0  text-info">
            Edit
          </p>
          <p role={"button"} className="card-text mx-3 mb-0  text-danger">
            Delete
          </p>
        </div>
      </div>
    </div>
  );
}

export default ListItem;
