import React from "react";

function BlogCard(props) {
  return (
    <div className="card">
      <div>
        <img
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
          alt="sercie"
          src={require(`../assets/${props.title}-${props.index + 1}.jpg`)}
        />{" "}
      </div>
    </div>
  );
}

export default BlogCard;
