import React from "react";

function Contact() {
  return (
    <div>
      <div className="bg-cyan-light h-8rem d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h3 className="fw-bold">Contact Us</h3>
        </div>
      </div>

      <section className="my-5 d-flex justify-content-center">
        <div className="col-10 col-md-4 mt-2">
          <h4 className="text-center">We always want to hear from you!</h4>
          <form className="mt-4">
            <div className="row mb-4">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone No"
                />
              </div>
              <div className="col-6">
                <input
                  placeholder="Email"
                  type="email"
                  className="form-control"
                />
              </div>
            </div>

            <textarea
              className="form-control"
              placeholder="Leave a comment here"
            ></textarea>

            <div className="d-flex  justify-content-center mt-4">
              <button type="submit" className="btn btn-primary px-5">
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default Contact;
