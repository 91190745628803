import React from "react";
import ListItem from "../../common/ListItem";
import { businessOwners } from "../../utils/constants";

function SchoolAdminBusinessOwners() {
  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3>Business Owners</h3>
        {businessOwners.map((x, i) => (
          <div className="py-2" key={i}>
            <ListItem title={x} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchoolAdminBusinessOwners;
