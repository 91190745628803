import React from "react";
import ListItem from "../../common/ListItem";
import { clubs } from "../../utils/constants";

function SchoolAdminClubs() {
  return (
    <div className="mt-5">
      <div className="container col-6">
        <h3>Clubs</h3>
        {clubs.map((x, i) => (
          <div className="py-2" key={i}>
            <ListItem title={`${x} club`} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchoolAdminClubs;
